html,
body {
	padding: 0;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
		Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

.selected-order-row td {
	background-color: #ffe58f;
}

.react-datepicker__day.react-datepicker__day.react-datepicker__day--selected,
.react-datepicker__day.react-datepicker__day.react-datepicker__day--keyboard-selected,
.react-datepicker__time-list-item.react-datepicker__time-list-item--selected {
	background-color: #319795 !important;
}

.react-datepicker-wrapper {
	width: auto !important;
}
